<template>
    <guest/>
    <!-- <guest v-if="!$store.getters.isLoggedIn" /> -->
    <!-- <auth v-else-if ="$store.getters.isLoggedIn" /> -->
    <!-- <coming /> -->
</template>

<script>
export default {
  metaInfo: {
    title: 'Arkadana',
  },
  components: {
    // auth: require("./views/template/Auth").default,
    guest: require("./views/template/Guest").default,
    // coming: require("./views/template/Maintenance").default,
  },
};
</script>

<style lang="scss"> 
  .theme--light.v-application {
    background-color: var(--v-background-base);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fade-up {
    from {
      width: 100%;
      position: absolute;
      opacity: 0;
      transform: translate3d(0, 50%, 0);
      left: 0;
      right: 0;
    }
    to {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .fade-enter-active {
    animation: fade 0.3s;
  }
  .fade-up-leave-active {
    animation: fade-up 0.3s reverse;
  }
  .fade-up-enter-active {
    animation: fade-up 0.3s;
  }
  a {
    text-decoration: none;
  }
</style>