<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <div class="">
        <v-main class="">          
          <v-container fluid style="padding: 0px;">
            <transition name="fade">
              <router-view v-if="loaded"></router-view>
            </transition>
          </v-container>

          <!-- <div id="footer" class="mt-3">
            <div class="col-md-11 mx-auto">
              <div class="row justify-center">
                <div class="col-md-4 text-center">
                  <p class="text-subtitle-1 font-weight-bold text-black">
                    <v-icon class="text-black">ri-instagram-fill</v-icon>
                    pawonsfoundation 
                  </p>
                </div>
                <div class="col-md-4 text-center">
                  <p class="text-subtitle-1 font-weight-bold text-black">
                    <v-icon class="text-black">ri-facebook-circle-fill</v-icon>
                    Pawons Foundation 
                  </p>
                </div>
                <div class="col-md-4 text-center">
                  <p class="text-subtitle-1 font-weight-bold text-black">
                    <v-icon class="text-black">ri-global-fill</v-icon>
                    pawonsfoundation.com
                  </p>
                </div>
              </div>
            </div>
          </div> -->
        </v-main>
      </div>
    </v-app>
  </transition>
</template>
<script>

export default {
  data() {
    return {
      breadcrumbs: [],
      loaded: true,
      drawer: false,
      group:null,
      notifications: [1],
      pageTitle: "",
      // title:false,
      pathnya: "",
      dialog:{
        menu:false,
      },
    };
  },
  computed: {
  },
  created() {
    this.$root.$on("setPageTitle", (pageTitle) => {
      this.pageTitle = pageTitle;
    });

    this.pathnya = this.$route.path;
  },
  methods: {
    updateLocation(item) {
      this.pageTitle = item.title;
    },
    pageChanged(segment, location = null) {
      this.pathnya = this.$route.path;
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }
      document.title =
        this.breadcrumbs.map((val) => val.text).join(" -> ") +
        " - " +
        process.env.VUE_APP_TITLE;
    },
  },
};
</script>
<style scoped>

</style>
