require("./lib");
import "./assets/style.css";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./lib/vuetify";
import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.config.productionTip = false;

(async () => {
  await store.dispatch("get_user");
	new Vue({
		created() {
		AOS.init()
	},
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
})();
