import Vue from 'vue'
import VueRouter from 'vue-router'
import Views from '../views'
import store from '../store'


Vue.use(VueRouter)

let routes = {
  auth: [

  ],
  guest: [
    { path: '/bantu-anak-yatim', name: 'home', component: Views.Public.PublicBeranda },
    { path: '/nutrisi-anak-yatim', name: 'nutrisi-anak-yatim', component: Views.Public.Nutrisi },
  ],
  default: [
    { path: '*', name: '404 Not Found', component: Views.Errors.NotFound },
    { path: '/coming-soon', name: 'coming-soon', component: Views.Template.Maintenance },

  ]
}

routes.auth.forEach(route => { route.meta = { ...route.meta, auth: true } });
routes.guest.forEach(route => { route.meta = { ...route.meta, guest: true } });
routes = [
  ...routes.auth,
  ...routes.guest,
  ...routes.default,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == '/bantu-anak-yatim' && store.getters.isLoggedIn) {
    return next('/bantu-anak-yatim')
  }
  if (to.meta.auth && !store.getters.isLoggedIn) {
    sessionStorage.setItem('ytc_prakerja_redirect', location.pathname)
    return next('/bantu-anak-yatim')
  }
  if (to.meta.guest && store.getters.isLoggedIn) {
    return next('/bantu-anak-yatim')
  }
  return next()
})

export default router
