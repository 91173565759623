<template>
  <div>
    <section id="banner-nutri">
      <div class="col-md-11 mx-auto">
          <div class="row no-gutters align-center" style="min-height: 70vh;">
            <div class="col-md-6 mx-auto primary--text align-self-center">
              <div data-aos="fade-up" data-aos-delay="300">
                <p class="text-left text-h3 font-bold mb-2" style="line-height: 1.1em;">Sempurnakan Pahala Ramadhan dengan Cukupi Nutrisi Anak Yatim</p>
                <div class="col-md-7">                
                  <p class="text-left text-h5 font-weight-light">Hanya dengan RP. 25.000 bantu cukupkan Nutrisi Anak Yatim</p>
                </div>
                <v-btn color="error" class="mb-2 text-h6 rounded-pill" href="https://wa.me/6282134667849" target="_blank" style="min-width: 300px;">
                  <b>Cukupkan nutrisinya!</b>
                </v-btn>
              </div>
            </div>   
            <div class="col-md-6 mx-auto align-self-center">
              <br class="d-dekstop">
              <br class="d-dekstop">
              <br class="d-dekstop">
              <br class="d-dekstop">
              <br class="d-dekstop">
              <iframe width="100%" height="415" class="ml-4" 
                  src="https://www.youtube.com/embed/pS7DgP7L8CM">
                  </iframe>
            </div>                     
          </div>
      </div>      
    </section>

    <section id="section-nutri-2">
      <div class="col-md-11 mx-auto">
          <div class="row no-gutters align-center" style="min-height: 70vh;">
            <div id="box-content" class="col-md-8 mx-auto white--text">
              <div data-aos="fade-up" data-aos-delay="300">                               
                <div class="text-center mb-10">
                  <p class="text-h3 font-bold">Santuni Anak Yatim, <br> InsyaAllah Menjadi Tetangga Rasulullah</p>     
                </div>
              </div>
              <br>
              <div data-aos="fade-up" data-aos-delay="300">          
                <p class="text-h6 font-weight-normal text-center">"Aku dan orang yang menanggung anak yatim (kedudukannya) di surga seperti ini, kemudian Beliau mengisyaratkan jari telunjuk dan jari tengah dan merenggangkan keduanya." (HR Bukhari)</p>
              </div>             
            </div>
          </div>
      </div>       
    </section>

    <section id="section-nutri-3" class="">
      <div class="col-md-11 mx-auto">
          <div class="row no-gutters align-center">
            <div class="col-md-6 mx-auto primary--text">
              <div data-aos="fade-up" data-aos-delay="300">
                <p class="text-h3 font-weight-medium" style="">Mengapa Harus Anak Yatim ?</p>                
              </div>
            </div>                     
          </div>
      </div>      
    </section>

    <section id="section-nutri-4" class="bg-primary">
      <div class="col-md-10 mx-auto">
          <div class="row no-gutters align-center">
            <div class="col-md-4 mx-auto pa-5 text-center mb-3">
              <div data-aos="fade-up" data-aos-delay="300">
                <img src="@/assets/images/nutrisi/smile.png" class="w-100" width="200">
                 <p class="text-h6 font-weight-normal text-center" style="color: #fff;">Bahagiakan Anak Yatim yang Hidup Tanpa Orang Tua</p>
              </div>
            </div>
            <div class="col-md-4 mx-auto pa-5 text-center mb-3">
              <div data-aos="fade-up" data-aos-delay="300">
                <img src="@/assets/images/nutrisi/night.png" class="w-100" width="200">
                 <p class="text-h6 font-weight-normal text-center" style="color: #fff;">InsyaAllah, jaminan surga menantimu</p>
              </div>
            </div>
            <div class="col-md-4 mx-auto pa-5 text-center mb-3">
              <div data-aos="fade-up" data-aos-delay="300">
                <img src="@/assets/images/nutrisi/hug.png" class="w-100" width="200">
                 <p class="text-h6 font-weight-normal text-center" style="color: #fff;">Menjadi tetangga Rasul di Surga</p>
              </div>
            </div>
            <div class="col-md-4 mx-auto pa-5 text-center mb-3">
              <div data-aos="fade-up" data-aos-delay="300">
                <img src="@/assets/images/nutrisi/house.png" class="w-100" width="200">
                 <p class="text-h6 font-weight-normal text-center" style="color: #fff;">Investasi pahala melimpah untuk surga</p>
              </div>
            </div>
            <div class="col-md-4 mx-auto pa-5 text-center mb-3">
              <div data-aos="fade-up" data-aos-delay="300">
                <img src="@/assets/images/nutrisi/fire.png" class="w-100" width="200">
                 <p class="text-h6 font-weight-normal text-center" style="color: #fff;">Jauhkan diri dari panas api neraka</p>
              </div>
            </div>
            <div class="col-md-4 mx-auto pa-5 text-center mb-3">
              <div data-aos="fade-up" data-aos-delay="300">
                <img src="@/assets/images/nutrisi/handshake.png" class="w-100" width="200">
                 <p class="text-h6 font-weight-normal text-center" style="color: #fff;">Mendapat Pertolongan dari Allah SWT</p>
              </div>
            </div>                     
          </div>
      </div>      
    </section>

    <section id="section-nutri-5">
      <div class="col-md-11 mx-auto">
          <div class="row no-gutters align-center" style="min-height: 90vh;">
            <div id="box-content" class="col-md-12 mx-auto white--text text-center">
              <div data-aos="fade-up" data-aos-delay="300" class="text-center">                                              
                <div class="text-center mb-10 mt-3">                  
                  <p class="text-h3 font-weight-medium">Senyum Bahagia Mereka, <br> InsyaAllah Surga Bagimu</p>    
                </div>
              </div>
            </div>
            <div class="col-md-4 pa-3 mx-auto text-center">            
              <img src="@/assets/images/nutrisi/img-1.jpg" class="img-banner-nutri rounded-xl box-shadow">
            </div>
            <div class="col-md-4 pa-3 mx-auto text-center">            
                <img src="@/assets/images/nutrisi/img-2.jpg" class="img-banner-nutri rounded-xl box-shadow">
            </div>
            <div class="col-md-4 pa-3 mx-auto text-center">            
                <img src="@/assets/images/nutrisi/img-3.jpg" class="img-banner-nutri rounded-xl box-shadow">
            </div>
            <div class="col-md-4 pa-3 mx-auto text-center">            
              <v-btn color="error" class="mb-2 text-h6 rounded-pill" href="https://wa.me/6282134667849" target="_blank" style="min-width: 300px;">
                  <b>SEDEKAH SEKARANG</b>
                </v-btn>
            </div>
          </div>
      </div>      
    </section>

    <section id="section-nutri-6" class="">
      <div class="col-md-10 mx-auto">
          <div class="row no-gutters align-center">
            <div class="col-md-4 mx-auto pa-5 text-center mb-3">
              <div data-aos="fade-up" data-aos-delay="300">
                <img src="@/assets/images/nutrisi/fruits.png" class="w-100" width="200">
                 <p class="text-h6 font-weight-normal text-center primary--text">Buah - buahan segar untuk sempurnakan nutrisi mereka</p>
              </div>
            </div>
            <div class="col-md-4 mx-auto pa-5 text-center mb-3">
              <div data-aos="fade-up" data-aos-delay="300">
                <img src="@/assets/images/nutrisi/milk.png" class="w-100" width="200">
                 <p class="text-h6 font-weight-normal text-center primary--text">Susu untuk bantu seimbangkan nutrisi mereka</p>
              </div>
            </div>
            <div class="col-md-4 mx-auto pa-5 text-center mb-3">
              <div data-aos="fade-up" data-aos-delay="300">
                <img src="@/assets/images/nutrisi/vitamin.png" class="w-100" width="200">
                 <p class="text-h6 font-weight-normal text-center primary--text">Vitamin membantu kesehatan dan daya tahan tubuh mereka</p>
              </div>
            </div>                   
          </div>
      </div>      
    </section>

    <!-- <section id="section-nutri-8" class="bg-white">
      <div class="col-md-11 mx-auto">
          <div class="row no-gutters align-center">
            <div id="box-content" class="col-md-7 mx-auto primary--text py-5">
              <br>
              <br>
              <div data-aos="fade-up" data-aos-delay="300">
                <div class="text-center mb-10">
                  <p class="text-h3 font-weight-medium" style="line-height: 1.1em">Sedekah sekarang! InsyaAllah menjadi tabungan Surga</p>        
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="300" class="text-center">          
                <v-btn color="#f15b29" large class="mb-2 text-h5 rounded-pill white--text" href="https://wa.me/6282134667849" target="_blank" style="min-width: 300px;">
                  <v-icon class="mr-2">ri-whatsapp-fill</v-icon>
                  <b>Donasi Sekarang</b>
                </v-btn>
              </div>             
            </div>
          </div>
      </div>      
    </section> -->

    <div id="footer-nutrisi" class="">
      <div class="col-md-11 mx-auto">
        <div class="row justify-center">
          <div id="box-content" class="col-md-7 mx-auto primary--text py-5" style="min-height: 85vh;">
              <br>
              <br>
              <div data-aos="fade-up" data-aos-delay="300">
                <div class="text-center mb-10">
                  <p class="text-h3 font-weight-medium" style="line-height: 1.1em">Sedekah sekarang! InsyaAllah menjadi tabungan Surga</p>        
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="300" class="text-center">          
                <v-btn color="#f15b29" large class="mb-2 text-h5 rounded-pill white--text" href="https://wa.me/6282134667849" target="_blank" style="min-width: 300px;">
                  <v-icon class="mr-2">ri-whatsapp-fill</v-icon>
                  <b>Donasi Sekarang</b>
                </v-btn>
              </div>             
            </div>
          <div class="row" style="position: absolute;bottom: 20px;">            
            <div class="col-md-12 text-left">
              <p class="text-h6 mb-0 white--text">Program</p>
              <p class="text-h6 mb-0 white--text font-weight-light">Kafalah Anak Yatim</p>
            </div>
            <div class="col-md-2 mx-auto text-center">
              <p class="text-subtitle-1 font-weight-bold white--text">
                <v-icon class="white--text">ri-global-fill</v-icon>
                gerakankebaikan.org
              </p>
            </div>
            <div class="col-md-3 mx-auto text-center">
              <p class="text-subtitle-1 font-weight-bold white--text">
                <v-icon class="white--text">ri-instagram-fill</v-icon>
                gerakankebaikanorg 
              </p>
            </div>
            <div class="col-md-3 mx-auto text-center">
              <p class="text-subtitle-1 font-weight-bold white--text">
                <v-icon class="white--text">ri-mail-fill</v-icon>
                gerakankebaikanorg@gmail.com
              </p>
            </div>
            <div class="col-md-2 mx-auto text-center">
              <p class="text-subtitle-1 font-weight-bold white--text">
                <v-icon class="white--text">ri-youtube-fill</v-icon>
                Gerakan Kebaikan 
              </p>
            </div>
            <div class="col-md-2 mx-auto text-center">
              <p class="text-subtitle-1 font-weight-bold white--text">
                <v-icon class="white--text">ri-whatsapp-fill</v-icon>
                +6282134667849
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "public-beranda",
  data: () => ({
   
  }),
  created() {
  },
  mounted() {
    
  },
  methods: {    
    login() {
      this.$router.push("/auth/login");
    },
    toCurrency: function (value) {
      if (typeof value !== "number") {
          return value;
      }
      // var formatter = new Intl.NumberFormat('en-IN', {
      var formatter = new Intl.NumberFormat('de-DE', {
          // style: 'currency',
          // currency: 'IDR',
          // minimumFractionDigits: 0
      });
      return formatter.format(value);
    }
  },
};
</script>

<style>

#banner-nutri{
  background-image: url("../../assets/images/nutrisi/background_01.jpg");
  padding: 50px 0;
  background-size: cover;
  background-position: bottom;
  height: 100vh;
}
#section-nutri-2{
  background-image: url("../../assets/images/nutrisi/background_03.jpg");
  /*padding: 80px 0;*/
  background-size: cover;
  background-position: bottom;
  min-height: 100vh;
}
#section-nutri-3{
  background-image: url("../../assets/images/nutrisi/background_04.jpg");
  padding: 50px 0;
  background-size: cover;
  background-position: unset;
  min-height: 90vh;
}
#section-nutri-4{
  background-color: #2b3991;
  /*background-image: url("../../assets/images/4.jpg");*/
  padding: 50px 0;
  /*background-size: cover;*/
  /*background-position: unset;*/
  min-height: 90vh;
}
#section-nutri-5{
  background-image: url("../../assets/images/nutrisi/background_06.jpg");
  padding: 50px 0 20px 0;
  background-size: cover;
  background-position: unset;
  min-height: 90vh;
}
#section-nutri-6{
  background-color: #dde2e8;
  /*background-image: url("../../assets/images/4.jpg");*/
  padding: 60px 0;
  /*background-size: cover;*/
  /*background-position: unset;*/
  min-height: 65vh;
}
#section-nutri-7{
  background-image: url("../../assets/images/6.jpg");
  padding: 80px 0;
  background-size: cover;
  background-position: unset;
  min-height: 100vh;
}
#section-nutri-8{
  /*background-image: url("../../assets/images/nutrisi/background_08.jpg");*/
  padding: 30px 0;
  /*background-size: cover;*/
  /*background-position: unset;*/
  /*min-height: 80vh;*/
}
#footer-nutrisi{
  background-image: url("../../assets/images/nutrisi/background_08.jpg");
  padding: 50px 0;
  background-size: cover;
  background-position: bottom;
  min-height: 80vh;
}
.img-banner-nutri{
  width: 100%;
  max-height: 350px;
  object-fit: cover;
}
.img-banner-vertical{
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}
@media (max-width: 425px) {
  #banner-nutri{background-size: auto;height: auto;}
  #section-nutri-8{background: #fff;}
  #banner-nutri .text-h3,
  #section-nutri-2 .text-h3,
  #section-nutri-3 .text-h3,
  #section-nutri-4 .text-h3,
  #section-nutri-5 .text-h3,
  #section-nutri-8 .text-h3,
  #section-nutri-7 .text-h3{
    font-size: 2rem!important;
    /*padding: 10px;*/
  }
  #section-nutri-6{background-color: #0480c1;} 
  #banner-nutri .text-h6{font-size: 1rem !important;}
  #box-content .text-h4{
    line-height: 3rem;
  }
  #box-content .text-subtitle-1{
    font-size: 0.9rem!important;
  }
  #footer-gk .text-h6{font-size: 13px!important;}
}
@media (max-width: 375px) {
  
}
</style>