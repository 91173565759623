<template>
  <div>
    <section id="banner-gk">
      <div class="col-md-11 mx-auto">
          <div class="row no-gutters align-center" style="min-height: 70vh;">
            <div class="col-md-6 mx-auto align-self-center">
              <br class="d-dekstop">
              <br class="d-dekstop">
              <br class="d-dekstop">
              <br class="d-dekstop">
              <br class="d-dekstop">
              <div data-aos="fade-up" data-aos-delay="300">
                <img src="@/assets/images/banner-1.jpg" class="img-banner-gk rounded-xl box-shadow">
              </div>
            </div>                     
            <div class="col-md-6 mx-auto white--text align-self-center">
              <div data-aos="fade-up" data-aos-delay="300">
                <p class="text-right text-h3 font-bold mb-2" style="line-height: 1.1em;">Menjadi Pahlawan untuk Wujudkan Cita - cita Anak Yatim Piatu</p>
                <div class="col-md-7 px-0 ms-auto">                
                  <p class="text-right text-h5 font-weight-light">Berbagi kebaikan untuk anak yatim inshallah pahala mengalir hingga ke surga.</p>
                </div>
              </div>
            </div>   
          </div>
      </div>      
    </section>

    <section id="section-gk-2">
      <div class="col-md-11 mx-auto">
          <div class="row no-gutters align-center" style="min-height: 70vh;">
            <div id="box-content" class="col-md-8 mx-auto primary--text">
              <div data-aos="fade-up" data-aos-delay="300">                               
                <div class="text-center mb-10">
                  <p class="text-h3 font-bold">Lapangkan Rezeki dengan Berdonasi untuk Anak Yatim</p>     
                </div>
              </div>
              <br>
              <div data-aos="fade-up" data-aos-delay="300">          
                <p class="text-h6 font-weight-normal text-center" style="color: #f15b29;">"Sungguh, Tuhanku melapangkan reseki dan membatasinya bagi siapa yang Dia kehendaki di antara hamba - hamba Nya." Dan apa saja yang kamu infakkan,Allah akan menggantinya dan Dialah pemberi rezeki yang terbaik QS. As - saba (39)</p>
              </div>             
            </div>
          </div>
      </div>       
    </section>

    <section id="section-gk-3" class="">
      <div class="col-md-11 mx-auto">
          <div class="row no-gutters align-center">
            <div class="col-md-6 mx-auto white--text">
              <div data-aos="fade-up" data-aos-delay="300">
                <p class="text-h3 font-weight-medium" style="">Sedikit Keikhlasanmu untuk Berbagi mampu Lukisakan Senyuman Indah di Wajah Mereka.</p>
                <p class="text-h6 font-weight-light text-left">Berdonasi sedikit atau banyak mampu lukiskan senyum yang luar biasa di wajah anak yatim piatu. Senyum di wajah mereka inshallah mampu membawamu hingga ke Surga.</p>
              </div>
            </div>   
            <div class="col-md-4 mx-auto">
              <div data-aos="fade-up" data-aos-delay="300">
                <img src="@/assets/images/banner-2.jpg" class="img-banner-vertical rounded-xl box-shadow">
              </div>
            </div>                     
          </div>
      </div>      
    </section>

    <section id="section-gk-4" class="bg-primary">
      <div class="col-md-11 mx-auto">
          <div class="row no-gutters align-center">
            <div class="col-md-6 mx-auto">
              <div data-aos="fade-up" data-aos-delay="300">
                <img src="@/assets/images/banner-3.jpg" class="wc-90 rounded-xl box-shadow">
              </div>
            </div>                     
            <div class="col-md-6 mx-auto white--text">
              <div data-aos="fade-up" data-aos-delay="300">
                <p class="text-h3 font-weight-medium" style="">Jadikan Kesempatan Terbaik untuk Mewujudkan Cita - cita Anak Yatim</p>
                <p class="text-h6 font-weight-light text-left">Jangan sia - siakan peluang untuk menjadi pahlawan terbaik di dunia. Ini waktunya kamu untuk wujudkan cita- cita anak yatim piatu.</p>
              </div>
            </div>   
          </div>
      </div>      
    </section>

    <section id="section-gk-5">
      <div class="col-md-11 mx-auto">
          <div class="row no-gutters align-center" style="min-height: 90vh;">
            <div id="box-content" class="col-md-7 mx-auto primary--text text-center">
              <div data-aos="fade-up" data-aos-delay="300" class="text-center">
                <img src="@/assets/images/banner-4.jpg" class="img-banner-gk rounded-xl box-shadow">                                
                <div class="text-center mb-10 mt-3">                  
                  <p class="text-h3 font-weight-medium" style="color: #f15b29;">Bantu Wujudkan Cita - Cita Anak Yatim Piatu di Panti Asuhan Yatim Piatu Al - Huda Pucung</p>    
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="300">          
                <p class="text-h6 font-weight-light text-center">Setiap anak di seluruh dunia pasti memiliki cita - cita yang luar biasa. Sama halnya dengan anak - anak di Panti Asuhan Yatim Piatu Al - Huda Pucung. Bagi mereka mewujudkan cita - cita tidak semudah dengan anak yang masih memiliki orang tua. Mereka sangat membutuhkan kebaikanmu untuk wujudkan cita - cita yang luar biasa.</p>
              </div>             
            </div>
          </div>
      </div>      
    </section>

    <section id="section-gk-8" class="bg-white">
      <div class="col-md-11 mx-auto">
          <div class="row no-gutters align-center" style="min-height: 100vh;">
            <div class="col-md-6 mx-auto">
              <div data-aos="fade-up" data-aos-delay="300">
                <img src="@/assets/images/banner-5.jpg" class="img-banner-vertical rounded-xl box-shadow">                                          
              </div>
            </div>
            <div class="col-md-2 mx-auto mb-2">
              <div data-aos="fade-up" data-aos-delay="300">
                <v-card class="text-center rounded-xl pa-3" color="#f15b29" dark height="280">
                  <v-card-text>
                    <v-img
                      src="@/assets/images/icon-location.png"
                      width="180"
                      class="mx-auto mb-3 filter"
                    ></v-img>
                    <p class="text-subtitle-2 font-weight-medium text-white mb-0">Pucung Wukisari Imogiri, Bantul, Yogyakarta, 55782</p>
                  </v-card-text>
                </v-card>
              </div>
            </div>
            <div class="col-md-2 mx-auto mb-2">
              <div data-aos="fade-up" data-aos-delay="300">
                <v-card class="text-center rounded-xl pa-3" color="#f15b29" dark height="280">
                  <v-card-text>
                    <v-img
                      src="@/assets/images/icon-donasi.png"
                      width="180"
                      class="mx-auto mb-3 filter"
                    ></v-img>
                    <p class="text-subtitle-2 font-weight-medium text-white mb-0">Donasi mulai 25.000</p>
                  </v-card-text>
                </v-card>
              </div>
            </div>
            <div id="box-content" class="col-md-7 mx-auto primary--text py-5">
              <br>
              <br>
              <div data-aos="fade-up" data-aos-delay="300">
                <div class="text-center mb-10">
                  <p class="text-h3 font-weight-medium" style="line-height: 1.1em">Sudah Siap Untuk Menjadi Pahlawan Bagi Anak Yatim?</p>        
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="300" class="text-center">          
                <p class="text-h4 font-weight-light text-center" style="color: #f15b29;">Mari sebarkan kebaikanmu, dengan berdonasi demi wujudkan cita  - cita anak yatim di Pondok Asuhan Yatim Piatu Al - Huda Pucung</p>
                <br>
                <v-btn color="#f15b29" large class="mb-2 text-h5 rounded-pill white--text" href="https://wa.me/6282134667849" target="_blank" style="min-width: 300px;">
                  <v-icon class="mr-2">ri-whatsapp-fill</v-icon>
                  <b>Donasi Sekarang</b>
                </v-btn>
              </div>             
            </div>
          </div>
      </div>      
    </section>

    <div id="footer-gk" class="" style="background: #2b3991;">
      <div class="col-md-11 mx-auto">
        <div class="row justify-center">
          <div class="col-md-12 text-left">
            <p class="text-h6 mb-0 white--text">Puncak DIgital Valey Jalan Parangtritis KM 5,6</p>
            <p class="text-h6 mb-0 white--text">Sewon Bantul Daerah Istimewa Yogyakarta</p>
          </div>
          <div class="col-md-4 text-center">
            <p class="text-subtitle-1 font-weight-bold white--text">
              <v-icon class="white--text">ri-global-fill</v-icon>
              gerakankebaikan.org
            </p>
          </div>
          <div class="col-md-4 text-center">
            <p class="text-subtitle-1 font-weight-bold white--text">
              <v-icon class="white--text">ri-instagram-fill</v-icon>
              gerakankebaikanorg 
            </p>
          </div>
          <div class="col-md-4 text-center">
            <p class="text-subtitle-1 font-weight-bold white--text">
              <v-icon class="white--text">ri-mail-fill</v-icon>
              gerakankebaikanorg@gmail.com
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "public-beranda",
  data: () => ({
   
  }),
  created() {
  },
  mounted() {
    
  },
  methods: {    
    login() {
      this.$router.push("/auth/login");
    },
    toCurrency: function (value) {
      if (typeof value !== "number") {
          return value;
      }
      // var formatter = new Intl.NumberFormat('en-IN', {
      var formatter = new Intl.NumberFormat('de-DE', {
          // style: 'currency',
          // currency: 'IDR',
          // minimumFractionDigits: 0
      });
      return formatter.format(value);
    }
  },
};
</script>

<style>

#banner-gk{
  background-image: url("../../assets/images/1.jpg");
  padding: 50px 0;
  background-size: cover;
  background-position: bottom;
  height: 100vh;
}
#section-gk-2{
  background-image: url("../../assets/images/2.jpg");
  /*padding: 80px 0;*/
  background-size: cover;
  background-position: bottom;
  min-height: 100vh;
}
#section-gk-3{
  background-image: url("../../assets/images/3.jpg");
  padding: 50px 0;
  background-size: cover;
  background-position: unset;
  min-height: 90vh;
}
#section-gk-4{
  background-image: url("../../assets/images/4.jpg");
  padding: 50px 0;
  background-size: cover;
  background-position: unset;
  min-height: 90vh;
}
#section-gk-5{
  background-image: url("../../assets/images/5.jpg");
  padding: 50px 0 20px 0;
  background-size: cover;
  background-position: unset;
  min-height: 90vh;
}
#section-gk-6{
  /*background-image: url("../../assets/images/6.jpg");*/
  padding: 5px 0;
  /*background-size: cover;*/
  /*background-position: unset;*/
  /*min-height: 100vh;*/
}
#section-gk-7{
  background-image: url("../../assets/images/6.jpg");
  padding: 80px 0;
  background-size: cover;
  background-position: unset;
  min-height: 100vh;
}
#section-gk-8{
  background-image: url("../../assets/images/7.jpg");
  padding: 10px 0 80px 0;
  background-size: cover;
  background-position: unset;
  min-height: 100vh;
}
.img-banner-gk{
  width: 100%;
  max-height: 350px;
  object-fit: cover;
}
.img-banner-vertical{
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}
@media (max-width: 425px) {
  #banner-gk{background-size: auto;height: auto;}
  #section-gk-8{background: #fff;}
  #banner-gk .text-h3,
  #section-gk-2 .text-h3,
  #section-gk-3 .text-h3,
  #section-gk-4 .text-h3,
  #section-gk-5 .text-h3,
  #section-gk-8 .text-h3,
  #section-gk-7 .text-h3{
    font-size: 2rem!important;
    /*padding: 10px;*/
  }
  #section-gk-6{background-color: #0480c1;} 
  #banner-gk .text-h6{font-size: 1rem !important;}
  #box-content .text-h4{
    line-height: 3rem;
  }
  #box-content .text-subtitle-1{
    font-size: 0.9rem!important;
  }
  #footer-gk .text-h6{font-size: 13px!important;}
}
@media (max-width: 375px) {
  
}
</style>