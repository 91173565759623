<template>
  <v-alert colored-border elevation="0" style="min-height: 100vh;">
    <p class="text-center py-16">
      <!-- <img src="@/assets/images/data_empty.png" /> -->
    </p>
    <h1 class="text-center">{{ message }}</h1>
  </v-alert>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "Error 404 - Page not found"
    }
  }
}
</script>
<template>
  <transition name="fade">
    <v-app>
      <div class="bg-error">        
        <v-container
          class="py-0"
        >
          <v-row
            class="align-center"
            no-gutters
            style="height: 100vh;"
          >
            <v-col>
              <v-card colored-border elevation="0" class="transparent">
                <p class="text-center mb-5">
                  <!-- <img src="@/assets/images/arkadana/img-404.png" width="400" height="auto" /> -->
                </p>
                <p class="primary--text text-center text-h3">404</p>
                <p class="primary--text text-center text-h6 font-weight-regular">Kami tidak menemukan halaman yang anda cari</p>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-app>
  </transition>
</template>
<script>

export default {

};
</script>
<style>

.bg-error{
  /*background-image: url("../../assets/images/arkadana/bg-error.png");*/
  /*padding: 50px 0;*/
  background-size: cover;
  background-position: center;
  /*min-height: 100vh;*/
}
</style>